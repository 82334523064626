import { gql, useMutation } from "@apollo/client";
import { Delete as TrashIcon } from "@mui/icons-material";
import { useContext } from "react";
import { useDisclosure } from "react-use-disclosure";
import TableIconButton from "../../../components/TableIconButton";
import { TableContext } from "../../../components/customDataGrid";
import ModalFrame from "../../../components/form/ModalFrame";
import FormInput from "../../../components/form/FormInput";
import { Form } from "react-final-form";

const GQLDelete = gql`
	mutation deletePackage($id: Int!, $adminNotes: String, $externalNotes: String) {
		deleted: deletePackage(id: $id, adminNotes: $adminNotes, externalNotes: $externalNotes)
	}
`;

export default function DeleteAction({ selectedRows = [] }) {
	const { isOpen, open, close } = useDisclosure();
	const { reload } = useContext(TableContext);

	const [Delete] = useMutation(GQLDelete, { fetchPolicy: "network-only" });

	const selectedPkg = selectedRows[0];
	const handleSubmit = async ({ adminNotes, externalNotes }) => {
		await Delete({ variables: { id: selectedPkg.id, adminNotes, externalNotes } });
		await reload();
		close();
	};

	return (
		<>
			<TableIconButton onClick={open} disabled={selectedRows.length !== 1 || selectedPkg.deletedAt !== null} icon={<TrashIcon />} tooltip={`Eliminar Registro${selectedRows.length === 1 ? "" : "s"}`} />
			<Form
				initialValues={{ id: selectedPkg?.id, adminNotes: selectedPkg?.adminNotes, externalNotes: selectedPkg?.externalNotes }}
				onSubmit={handleSubmit}
				// validate={validate}
				render={({ handleSubmit, values }) => (
					<ModalFrame
						onSubmit={handleSubmit}
						{...{ isOpen, close }}
						title={`Estas seguro que deseas eliminar el paquete?`}
						subtitle={`${selectedRows.length === 1 ? selectedRows[0]?.title : selectedRows.length}`}>
						<FormInput name="adminNotes" label="Notas" type="textarea" />
						<FormInput name="externalNotes" label="Notas Externas" type="textarea" sx={{ marginTop: 4 }} />
					</ModalFrame>
				)}
			/>
		</>
	);
}
