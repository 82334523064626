import { gql, useLazyQuery, useMutation } from "@apollo/client";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import { Alert, Stack, Autocomplete, TextField, RadioGroup, FormControlLabel, Radio, FormControl} from "@mui/material";
import { useMemo, useState, useCallback } from "react";
import { Form, FormSpy } from "react-final-form";
import { useDispatch } from "react-redux";
import { useDisclosure } from "react-use-disclosure";
import FormDateInput from "../../../components/form/FormDateInput";
import FormInput from "../../../components/form/FormInput";
import FormTimeInput from "../../../components/form/FormTimeInput";
import ModalFrame from "../../../components/form/ModalFrame";
import TableIconButton from "../../../components/TableIconButton";
import { setSnackBar } from "../../../redux/snackbar";
import React from "react";
import FormSlider from "../../../components/form/FormSlider";
import FormAddress from "../../../components/form/FormAddress";

const ACTIONS_TYPES = {
  COPY: 'copy',
  TECHNICAL: 'technical'
};
const CREATE_TECHNICAL_VISIT = gql`
  mutation CreateTechnicalVisit(
    $ownerEmail: String!
    $address: JSONObject!
    $title: String!
    $description: String!
    $categoryId: Int!
    $subcategoryId: Int
    $price: Float!
    $userTerms: String
    $providerTerms: String
    $workDate: DateTime!
    $media: [JSONObject]
    $acquisitionMethodId: Int
    $externalId: String
    $totalPrice: Float!
    $adminNotes: String
    $revenueRate: Float
    $ownerFirstName: String!
    $ownerLastName: String!
    $branch: String
    $seller: String
    $dni: String
    $providerId: Int
    $originalPackageId: Int!
    $ownerId: Int
	$addressId: Int
	$telephone: String
  ) {
    createdPackage: createTechnicalVisit(
      ownerEmail: $ownerEmail
      address: $address
      title: $title
      description: $description
      categoryId: $categoryId
      subcategoryId: $subcategoryId
      price: $price
      userTerms: $userTerms
      providerTerms: $providerTerms
      workDate: $workDate
      media: $media
      acquisitionMethodId: $acquisitionMethodId
      externalId: $externalId
      totalPrice: $totalPrice
      adminNotes: $adminNotes
      revenueRate: $revenueRate
      ownerFirstName: $ownerFirstName
      ownerLastName: $ownerLastName
      branch: $branch
      seller: $seller
      dni: $dni
      providerId: $providerId
      originalPackageId: $originalPackageId
      ownerId: $ownerId
      addressId: $addressId
      telephone: $telephone
    ) {
      id
    }
  }
`;

const GET_PROVIDERS = gql`
    query GetProviders($lat: Float!, $lng: Float!, $radius: Float!, $providerCategories: [JSON]) {
        providers: getProvidersInArea(lat: $lat, lng: $lng, radius: $radius, providerCategories: $providerCategories) {
            id
            firstName
            lastName
            profilePicture
            address {
                id
                lat
                lng
                inline
            }
        }
    }
`;

const GET_CATEGORIES = gql`
    query getCategoriesForSelect {
        categories: getAllCategories {
            id
            titleLng
        }
    }
`;

const GET_SUBCATEGORIES = gql`
    query getAllCategories {
        subcategories: getAllSubcategories {
            id
            title {
                id
                es
                en
                pt
            }
            categoryId
            category {
                id
                title {
                    id
                    es
                    en
                    pt
                }
            }
            createdAt
            deletedAt
        }
    }
`;

const CreateTechnicalVisitAction = ({ selectedRows = [], disabled, reload }) => {
  const { isOpen, open, close } = useDisclosure(false);
  const dispatch = useDispatch();
  const [selectedProvider, setSelectedProvider] = useState(null);
  const [formRef, setFormRef] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubcategory, setSelectedSubcategory] = useState(null);
  const [actionType, setActionType] = useState('copy');

  const packageData = useMemo(() => {
    if (selectedRows?.length !== 1) {
      return null;
    }
    const currentRow = selectedRows[0];
    
    
    const workDateTime = new Date(currentRow?.workDate);

    const address = currentRow?.address ? {
      ...currentRow.address,
      lat: parseFloat(currentRow.address.lat),
      lng: parseFloat(currentRow.address.lng)
    } : null;
    const isTechnicalVisit = actionType === ACTIONS_TYPES.TECHNICAL;

    const baseTitle = currentRow?.title;
    const baseDescription = currentRow?.description;
    const title = isTechnicalVisit ? `Visita Técnica - ${baseTitle}` : baseTitle;
    const description = isTechnicalVisit ? `Visita Técnica - ${baseDescription}` : baseDescription;
    const defaultCategory = isTechnicalVisit ? 11 : currentRow?.categoryId;
    const defaultUserTerms = isTechnicalVisit ? "\nEl servicio incluye:\nVisita técnica y diagnóstico." : currentRow?.userTerms;
    const defaultProviderTerms = isTechnicalVisit ? "\nEl servicio incluye:\nVisita técnica y diagnóstico." : currentRow?.providerTerms;
    
    return {
      originalPackageId: currentRow?.id,
      email: currentRow?.owner?.email,
      workDate: isTechnicalVisit ? null : workDateTime,
      workTime: isTechnicalVisit ? null : workDateTime,
      firstName: currentRow?.owner?.firstName,
      lastName: currentRow?.owner?.lastName,
      branch: currentRow?.branch,
      seller: currentRow?.seller,
      dni: currentRow?.dni,
      externalId: currentRow?.externalId,
      title: title,
      description: description,
      price: isTechnicalVisit ? 0 : currentRow?.price,
      revenueRate: isTechnicalVisit ? 0 : currentRow?.revenueRate,
      totalPrice: isTechnicalVisit ? 0 : currentRow?.totalPrice,
      userTerms: isTechnicalVisit ? defaultUserTerms : currentRow?.userTerms,
      providerTerms: isTechnicalVisit ? defaultProviderTerms : currentRow?.providerTerms,
      adminNotes: currentRow?.adminNotes,
      address: address,
      categoryId: defaultCategory,
      subcategoryId: currentRow?.subcategoryId,
      media: currentRow?.media,
      acquisitionMethodId: currentRow?.acquisitionMethodId,
      searchRadius: 80,
      providerId: currentRow.providerId ? parseInt(currentRow.providerId) : null,
      ownerId: currentRow?.ownerId,
      addressId: currentRow?.addressId,
      telephone: currentRow?.telephone
    };
  }, [selectedRows, actionType]);

  const [createVisit] = useMutation(CREATE_TECHNICAL_VISIT);
  const [getProviders, { data: providersData, loading }] = useLazyQuery(GET_PROVIDERS, { 
    fetchPolicy: "network-only" 
  });
  const [getCategories, { data: categoriesData }] = useLazyQuery(GET_CATEGORIES, { 
    fetchPolicy: "network-only" 
  });
  const [getSubcategories, { data: subcategoriesData }] = useLazyQuery(GET_SUBCATEGORIES);

  const lastAddress = React.useRef(null);

  const loadProviders = useCallback((lat, lng, radius) => {
    const numLat = parseFloat(lat);
    const numLng = parseFloat(lng);

    const addressKey = `${numLat},${numLng},${radius}`;
    
    if (lastAddress.current === addressKey) {
      return;
    }
    
    if (isOpen && !isNaN(numLat) && !isNaN(numLng)) {
      lastAddress.current = addressKey;

      const providerCategories = selectedRows.map(row => ({
        categoryId: row.categoryId,
        subcategoryId: row.subcategoryId
      }));

      getProviders({
        variables: {
          lat: numLat,
          lng: numLng,
          radius: radius || 80,
          providerCategories
        }
      }).catch(error => {
        console.error('Error fetching providers:', error);
      });
    }
  }, [isOpen, getProviders, selectedRows]);

  React.useEffect(() => {
    if (isOpen && selectedRows[0]?.address?.lat && selectedRows[0]?.address?.lng) {
      loadProviders(
        selectedRows[0].address.lat,
        selectedRows[0].address.lng,
        packageData?.searchRadius || 80
      );
    }
  }, [isOpen, selectedRows, loadProviders, packageData?.searchRadius]);

  React.useEffect(() => {
    if (providersData?.providers && selectedRows[0]?.providerId) {
      const provider = providersData.providers.find(
        p => String(p.id) === String(selectedRows[0].providerId)
      );
      if (provider) {
        setSelectedProvider(prevProvider => {
          if (prevProvider?.id !== provider.id) {
            if (provider.address && formRef) {
              formRef.change('address', {
                ...provider.address,
                inline: provider.address.inline || '',
                lat: provider.address.lat,
                lng: provider.address.lng
              });
            }
            return provider;
          }
          return prevProvider;
        });
      }
    }
  }, [providersData?.providers, selectedRows, formRef]);

  const validate = (values) => {
    const errors = {};

    if (!values.workDate) errors.workDate = "Required";
    if (!values.workTime) errors.workTime = "Required";
    if (!values.email) errors.email = "Required";
    if (!values.title) errors.title = "Required";
	  if (!values.firstName) errors.firstName = "Required";
    if (!values.lastName) errors.lastName = "Required";
    if (!values.description) errors.description = "Required";
    if (!values.categoryId) errors.categoryId = "Required";
    if (!values.subcategoryId) errors.subcategoryId = "Required";
    return errors;
  };

  const handleSubmit = async (values) => {
    try {
      const { workDate, workTime, searchRadius, email, firstName, lastName, ...restValues } = values;
      
      const finalWorkDate = new Date(workDate);
      if (workTime) {
        finalWorkDate.setHours(workTime.getHours());
        finalWorkDate.setMinutes(workTime.getMinutes());
      }
  
      // Asegurarse de que los valores numéricos sean números
      const numericPrice = parseFloat(restValues.price);
      const numericTotalPrice = parseFloat(restValues.totalPrice);
      const numericRevenueRate = parseFloat(restValues.revenueRate);
  
      if (isNaN(numericPrice) || isNaN(numericTotalPrice) || isNaN(numericRevenueRate)) {
        throw new Error('Los precios deben ser valores numéricos válidos');
      }
      const variables = {
        ...restValues,
        ownerEmail: email, 
        ownerFirstName: firstName, 
        ownerLastName: lastName,
        workDate: finalWorkDate.toISOString(),
        originalPackageId: parseInt(packageData.originalPackageId),
        price: numericPrice,
        totalPrice: numericTotalPrice,
        ownerId: packageData.ownerId,
        addressId: packageData.addressId,
        revenueRate: numericRevenueRate,
      }
    

      const isTechnicalVisit = actionType === ACTIONS_TYPES.TECHNICAL;
      if (isTechnicalVisit) {
        variables.acquisitionMethodId = null;
      }
  
      const { data } = await createVisit({
        variables
      });
  
      if (data?.createdPackage) {
        dispatch(setSnackBar({ text: "Visita técnica creada correctamente." }));
        close();
        if (reload) reload();
      }
    } catch (error) {
      console.error('Error creating technical visit:', error);
      dispatch(setSnackBar({ 
        text: "Error al crear la visita técnica: " + (error.message || "Error desconocido"), 
        severity: "error" 
      }));
    }
  };

  React.useEffect(() => {
    if (isOpen) {
      getCategories();
      getSubcategories();
    }
  }, [isOpen, getCategories, getSubcategories]);

  // Filtrar subcategorías basado en la categoría seleccionada y que no estén eliminadas
  const filteredSubcategories = useMemo(() => {
    if (!subcategoriesData?.subcategories) return [];
    return subcategoriesData.subcategories.filter(
      sub => sub.categoryId === selectedCategory?.id && !sub.deletedAt
    );
  }, [subcategoriesData?.subcategories, selectedCategory]);

  // Add these effects to set initial category and subcategory
  React.useEffect(() => {
    if (categoriesData?.categories && packageData?.categoryId) {
      const category = categoriesData.categories.find(
        cat => cat.id === packageData.categoryId
      );
      if (category) {
        setSelectedCategory(category);
      }
    }
  }, [categoriesData?.categories, packageData?.categoryId]);

  React.useEffect(() => {
    if (subcategoriesData?.subcategories && packageData?.subcategoryId) {
      const subcategory = subcategoriesData.subcategories.find(
        sub => sub.id === packageData.subcategoryId
      );
      if (subcategory) {
        setSelectedSubcategory(subcategory);
      }
    }
  }, [subcategoriesData?.subcategories, packageData?.subcategoryId]);

  return (
    <>
      <TableIconButton 
        onClick={open}
        disabled={disabled || selectedRows.length !== 1}
        icon={<ContentCopyIcon />}
        tooltip='Duplicar Paquete'
      />
      <Form
        onSubmit={handleSubmit}
        initialValues={packageData}
        validate={validate}
        render={({ handleSubmit, values = {}, valid, form }) => (
          <ModalFrame
            onSubmit={handleSubmit}
            isOpen={isOpen}
            close={close}
            title={actionType === ACTIONS_TYPES.TECHNICAL ? "Crear visita técnica" : "Duplicar paquete"}
            submitDisabled={!valid}
            buttonTexts={[actionType === ACTIONS_TYPES.TECHNICAL ? "Crear" : "Copiar", "Cancelar"]}
          >
            <Stack spacing={4}>
              <FormControl>
                <RadioGroup
                  row
                  value={actionType}
                  onChange={(e) => {
                    setActionType(e.target.value);
                    if (e.target.value === ACTIONS_TYPES.TECHNICAL && categoriesData?.categories) {
                      const mannoPro = categoriesData.categories.find(cat => cat.id === 11);
                      setSelectedCategory(mannoPro);
                      setSelectedSubcategory(null);
                    }
                  }}
                >
                  <FormControlLabel 
                    value={ACTIONS_TYPES.COPY} 
                    control={<Radio />} 
                    label="Copiar" 
                  />
                  <FormControlLabel 
                    value={ACTIONS_TYPES.TECHNICAL} 
                    control={<Radio />} 
                    label="Visita Técnica" 
                  />
                </RadioGroup>
              </FormControl>

              <Stack direction={"row"} gap={2}>
                <FormInput label="Email" name="email" autoFocus required />
                <FormDateInput name="workDate" label="Fecha de la visita" disablePast required />
                <FormTimeInput name="workTime" label="Hora de la visita" required />
              </Stack>
              <Stack direction={"row"} gap={2}>
                <FormInput label="Nombre del Cliente" name="firstName" required />
                <FormInput label="Apellido del Cliente" name="lastName" required />
              </Stack>
              <Stack direction={"row"} gap={2}>
                <FormInput label="Sucursal" name="branch" />
                <FormInput label="Vendedor" name="seller" />
              </Stack>
              <Stack direction={"row"} gap={2}>
                <FormInput label="dni / factura" name="dni" />
                <FormInput label="Teléfono" name="telephone" type="tel" />
                <FormInput 
									label="Id Externo" 
									name="externalId"
									disabled={selectedRows[0]?.acquisitionMethod?.showAutoIncrementId === true}
								/>
              </Stack>

              <FormAddress 
                label="Direccion" 
                name="address" 
                required
              />
              <Stack direction="row" gap={2}>
                <Stack direction="row" spacing={2} alignItems="center" sx={{ flex: 1 }}>
                  <FormSlider 
                    name="searchRadius" 
                    label={`Radio de búsqueda: ${values?.searchRadius || 80} km`}
                    min={1} 
                    max={80}
                    defaultValue={80}
                    disabled={!values?.address?.lat || !values?.address?.lng}
                  />
                  <FormSpy subscription={{ values: true }}>
                    {({ values }) => {
                      if (values?.address?.lat && values?.address?.lng) {
                        loadProviders(values.address.lat, values.address.lng, values?.searchRadius || 80);
                      }
                      return null;
                    }}
                  </FormSpy>
                </Stack>
            
                <Autocomplete
                  sx={{ flex: 2 }}
                  options={providersData?.providers || []}
                  getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
                  value={selectedProvider}
                  isOptionEqualToValue={(option, value) => parseInt(option.id) === parseInt(value.id)}
                  renderInput={(params) => (
                    <TextField 
                      {...params} 
                      label="Experto" 
                      helperText={loading ? "Cargando expertos..." : "Escriba para filtrar expertos"}
                    />
                  )}
                  onChange={(_, newValue) => {
                    setSelectedProvider(newValue);
                    form.change('providerId', newValue?.id || null);
                  }}
                  filterOptions={(options, { inputValue }) => {
                    const searchText = inputValue.toLowerCase();
                    return options.filter(
                      option => 
                        option.firstName.toLowerCase().includes(searchText) ||
                        option.lastName.toLowerCase().includes(searchText)
                    );
                  }}
                  loading={loading}
                  noOptionsText="No se encontraron expertos"
                />
              </Stack>

              {actionType === ACTIONS_TYPES.TECHNICAL && selectedRows[0]?.categoryId && (
                <Alert severity="info" sx={{ mb: 1, width: '100%' }}>
                  <div>
                    Categoría original: {
                      categoriesData?.categories?.find(cat => cat.id === selectedRows[0]?.categoryId)?.titleLng || 'No encontrada'
                    }
                  </div>
                  <div>
                    Subcategoría original: {
                      subcategoriesData?.subcategories?.find(sub => sub.id === selectedRows[0]?.subcategoryId)?.title?.es || 'No encontrada'
                    }
                  </div>
                </Alert>
              )}

              <Stack direction="row" gap={2}>
                <Stack sx={{ flex: 1 }}>
                  <Autocomplete
                    options={categoriesData?.categories || []}
                    getOptionLabel={(option) => option.titleLng || ''}
                    value={selectedCategory}
                    onChange={(_, newValue) => {
                      setSelectedCategory(newValue);
                      setSelectedSubcategory(null);
                      form.change('categoryId', newValue?.id || null);
                      form.change('subcategoryId', null);
                    }}
                    isOptionEqualToValue={(option, value) => option?.id === value?.id}
                    renderInput={(params) => (
                      <TextField 
                        {...params} 
                        label="Categoría" 
                        helperText="Seleccione una categoría"
                      />
                    )}
                    noOptionsText="No se encontraron categorías"
                    required
                  />
                </Stack>

                <Stack sx={{ flex: 1 }}>
                  <Autocomplete
                    options={filteredSubcategories}
                    getOptionLabel={(option) => option.title?.es || ''}
                    value={actionType === ACTIONS_TYPES.TECHNICAL ? selectedSubcategory : (subcategoriesData?.subcategories?.find(sub => sub.id === values?.subcategoryId) || selectedSubcategory)}
                    onChange={(_, newValue) => {
                      setSelectedSubcategory(newValue);
                      form.change('subcategoryId', newValue?.id || null);
                    }}
                    disabled={!selectedCategory}
                    isOptionEqualToValue={(option, value) => option?.id === value?.id}
                    renderInput={(params) => (
                      <TextField 
                        {...params} 
                        label="Subcategoría" 
                        helperText={selectedCategory ? "Seleccione una subcategoría" : "Primero seleccione una categoría"}
                      />
                    )}
                    noOptionsText={!selectedCategory 
                      ? "Seleccione una categoría primero"
                      : "No se encontraron subcategorías"
                    }
                    required
                  />
                </Stack>
              </Stack>
              <Stack direction="row" gap={2}>
                <FormInput label="Notas" name="adminNotes" type="textarea" />
              </Stack>

              <Stack direction="row" gap={2}>
                <FormInput label="Título" name="title" required />
                <FormInput label="Importe a abonar al experto" name="price" type="number" required fullWidth={false} />
                <FormInput label="% de Ganancia" name="revenueRate" type="number" required fullWidth={false} />
              </Stack>
              <Stack direction="row" gap={2}>
                <FormInput label="Descripcion" name="description" required />
                <FormInput label="Importe cobrado al usuario" name="totalPrice" type="number" required fullWidth={false} />
              </Stack>
              <Stack direction="row" spacing={2}>
                <FormInput label="TyC Usuario" name="userTerms" multiline rows={8} />
                <FormInput label="TyC Expertos" name="providerTerms" multiline rows={8} />
              </Stack>
            </Stack>
          </ModalFrame>
        )}
      />
    </>
  );
};

export default CreateTechnicalVisitAction; 