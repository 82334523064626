import { Box, Typography } from "@mui/material";
import React from "react";
import noData from "./no-data.jpg";

export default function EmptyComponent({reload}) {
	

	return (
		<Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", height: "100%", padding: 4 }}>
			<img src={noData} alt="No hay datos" style={{ height: "100%", maxHeight: "100px" }}/>
			<Typography variant="h6">No hay datos</Typography>
		</Box>
	);
}
