import { useLazyQuery } from '@apollo/client';
import { Box, Card } from '@mui/material';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { CustomDataGrid } from '../../../components';
import CustomFilterPanelForTable from '../../../components/customFilterPanelForTable';
import { selectCompleteDate } from '../../../redux/layout';
import { getContacts } from '../GQLQueries';


export const CustomerListResults = (props) => {
  
  const [dateFrom, dateTo] = useSelector(selectCompleteDate);
  const [runQuery, { data, loading }] = useLazyQuery(getContacts, { variables: { dateFrom: dateFrom, dateTo: dateTo }, fetchPolicy: "network-only" });
  const rows = data?.contacts || []

  const loadData = () => {
    if (dateFrom && dateTo) {
      runQuery({ variables: { dateFrom, dateTo } })
    }
  }
  useEffect(loadData, [dateFrom, dateTo, runQuery])


  return (<>
    
    <Card {...props}>
      <Box sx={{ minWidth: 1050 }}>
        <CustomDataGrid
          filterPanel={CustomFilterPanelForTable}
          reload={loadData}
          loading={loading}
          actions={[]}
          hideFooterSelectedRowCount
          checkboxSelection
          label=""
          rows={rows}
          columns={[
            { id: "contactId", label: "ID", type: "string", hide: true },
            { id: "firstName", label: "Nombre", type: "string" },
            { id: "lastName", label: "Apellido", type: "string" },
            { id: "phoneNumber", label: "Teléfono", type: "string" },
            { id: "email", label: "Correo electrónico", type: "string" },
            
            { id: "tags", label: "Etiquetas", type: "string" },
            { id: "status", label: "Estado", type: "string" },
            { id: "lifecycle", label: "Ciclo de vida", type: "string" },
            { id: "assignee", label: "Asignado a", type: "string" },
            { id: "lastInteractionTime", label: "Última interacción", type: "date" },
            { id: "createdAt", label: "Fecha de creación", type: "date" },
            { id: "channels", label: "Canales", type: "string" },
            { id: "alliance", label: "Alianza", type: "string" },
            { id: "product", label: "Producto", type: "string" },
            { id: "internalAllianceId", label: "ID de alianza interna", type: "string" },
            { id: "productId", label: "ID de producto", type: "string" },
            { id: "saleDate", label: "Fecha de venta", type: "date" },
            { id: "address", label: "Dirección", type: "string" },
            { id: "floor", label: "Piso/depto/casa", type: "string" },
            { id: "location", label: "Localidad", type: "string" },
            { id: "installationDate", label: "Día de instalación", type: "date" },
            { id: "installationTime", label: "Horario de instalación", type: "string" },
            { id: "megatoneSellerId", label: "ID vendedor Megatone", type: "string" },
            { id: "fravegaSellerId", label: "ID vendedor Fravega", type: "string" },
            { id: "rodoSellerId", label: "ID vendedor Rodo", type: "string" },
            { id: "dni", label: "DNI", type: "string" },
            { id: "installments", label: "Cuotas", type: "number" },
            { id: "card", label: "Tarjeta", type: "string" },
            { id: "paymentLink", label: "Link de pago", type: "string" },
            { id: "survey", label: "Encuesta", type: "string" },
            { id: "uninstallation", label: "Desinstalación", type: "string" },
            { id: "appId", label: "ID de aplicación", type: "string" },
            { id: "service", label: "Servicio", type: "string" },
            { id: "branch", label: "Sucursal", type: "string" },
            { id: "purchaseMedium", label: "Medio de compra", type: "string" },
            { id: "botExperience", label: "Experiencia bot", type: "string" },
            { id: "installerAttention", label: "Atención del instalador", type: "string" },
            { id: "advisorAttention", label: "Atención del asesor", type: "string" },
            { id: "generalExperienceWithManno", label: "Experiencia general con Manno", type: "string" },
            { id: "wouldRehire", label: "Volvería a contratar", type: "string" },
            { id: "claims", label: "Reclamos", type: "string" },
            { id: "balconyTerrace", label: "Balcón/Terraza", type: "string" },
            { id: "followup24Newsan", label: "Seguimiento 24 Newsan", type: "number" },
            { id: "followup48", label: "Seguimiento 48", type: "number" },
            { id: "followup72", label: "Seguimiento 72", type: "number" },
            { id: "followup24Megatone", label: "Seguimiento 24 Megatone", type: "number" },
            { id: "saleCompleted", label: "Venta concretada", type: "string" },
            { id: "saleWithInstallation", label: "Venta con instalación", type: "string" },
            { id: "seller", label: "Vendedor", type: "string" },
            { id: "sellerName", label: "Nombre del vendedor", type: "string" },
            { id: "sellerLedger", label: "Legajo del vendedor", type: "string" },
            { id: "followup24Fravega", label: "Seguimiento 24 Fravega", type: "string" },
            { id: "invoiceNumber", label: "Número de factura", type: "string" },
            { id: "language", label: "Idioma", type: "string" },
            { id: "country", label: "País", type: "string" },
            { id: "province", label: "Provincia", type: "string" },
            { id: "city", label: "Ciudad", type: "string" },
            { id: "mannoCity", label: "Ciudad Manno", type: "string" },
            { id: "vtOnCity", label: "VT OnCity", type: "string" },
            { id: "followup24Musimundo", label: "Seguimiento 24 Musimundo", type: "string" },
            { id: "abandoned", label: "Abandonada", type: "string" },
            { id: "deciding", label: "Decidiendo", type: "string" },
            { id: "hired", label: "Contratado", type: "string" },
            { id: "assignee", label: "Asignado a", type: "string" },
            { id: "alliance", label: "Alianza", type: "string" },
            { id: "product", label: "Producto", type: "string" },
            { id: "channel", label: "Canal", type: "string" },
            { id: "channels", label: "Canales", type: "string" },

          ]} />
      </Box>
    </Card>
  </>);
};