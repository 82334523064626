import { gql, useMutation } from "@apollo/client";
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import React from "react";
import TableIconButton from "../../../components/TableIconButton";

const SEND_NOTIFICATIONS = gql`
  mutation SendNotificationsToProviders($packageIds: [Int!]!) {
    sendNotificationsToProviders(packageIds: $packageIds)
  }
`;

const SendNotificationsAllProviders = ({ selectedRows = [], disabled }) => {
  const [sendNotifications, { loading }] = useMutation(SEND_NOTIFICATIONS);

  const hasAssignedProvider = selectedRows.some(row => row.providerId);

  const handleClick = async () => {
    try {
      await sendNotifications({
        variables: {
          packageIds: selectedRows.map(row => parseInt(row.id))
        }
      });
    } catch (error) {
      // Silent error handling
    }
  };

  return (
    <TableIconButton 
      onClick={handleClick} 
      disabled={disabled || loading || selectedRows.length === 0 || hasAssignedProvider} 
      icon={<NotificationsActiveIcon />} 
      tooltip='Notificar a expertos' 
    />
  );
};

export default SendNotificationsAllProviders; 