import { gql } from "@apollo/client";

export const getLastImportCsvStatus = gql`
	query {
		importCsvStatus: getLastImportCsvStatus {
			id
			status
			error
			createdAt
		}
	}
`;





