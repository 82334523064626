import { Box, Modal, Table, TableCell, TableHead, TableRow, Typography, IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import React, { useState, useImperativeHandle, forwardRef } from "react";

const ModalUploadResult = forwardRef(({ errors = [] }, ref) => {
	const [open, setOpen] = useState(false);
	const handleClose = () => setOpen(false);

	useImperativeHandle(ref, () => ({
		open: () => setOpen(true),
	}));

	return (
		<Modal open={open} onClose={handleClose}>
			<Box
				sx={{
					position: "absolute",
					top: "50%",
					left: "50%",
					maxHeight: "70%",
					transform: "translate(-50%, -50%)",
					width: "100%",
					maxWidth: "80%",
					bgcolor: "background.paper",
					borderRadius: 1,
					boxShadow: 24,
					p: 4,
					overflowY: "scroll",
				}}>
				<IconButton
					sx={{ position: 'absolute', top: 16, right: 16 }}
					onClick={handleClose}
					aria-label="close"
				>
					<CloseIcon />
				</IconButton>
				<Typography variant="h5">Errores</Typography>
				
				<Table sx={{ width: '100%', mt: 2 }}>
					<TableHead>
						<TableRow>
							<TableCell>customerId</TableCell>
							<TableCell>campo</TableCell>
							<TableCell>Valor</TableCell>
							<TableCell>Mensaje</TableCell>
							<TableCell>Resultado</TableCell>
						</TableRow>
					</TableHead>

					{errors?.map((result) => (
						<TableRow key={result.id}>
							<TableCell>{result.id}</TableCell>
							<TableCell>{result.field}</TableCell>
							<TableCell>{result.value}</TableCell>
							<TableCell>{result.message}</TableCell>
							<TableCell>{result.result}</TableCell>
						</TableRow>
					))}
				</Table>
			</Box>
		</Modal>
	);
});

export default ModalUploadResult;
