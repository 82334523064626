import { Box } from "@mui/material";

/**
 * 
 * @param props 
 * @returns 
 */
function CustomTabPanel({children, show =true}) {
  

  return (
    <Box sx={{ p: 3 }}>
      {show && children}
    </Box>

  );
}
export default CustomTabPanel;