import { useQuery } from "@apollo/client";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Box, Card, CardContent, CardHeader, IconButton, Paper } from "@mui/material";
import _ from "lodash";
import * as React from "react";
import { useSelector } from "react-redux";
import CustomDataGrid from "../../../components/customDataGrid";
import { selectCompleteDate } from "../../../redux/layout";
import { getContactReport } from "../GQLQueries";
import useColumnsDictionary from "../hooks/useColumnsDictionary";
import DeleteButtonCustomerReportTable from "./DeleteButtonCustomerReportTable";
import EditButtonCustomerReportTable from "./EditButtonCustomerReportTable";
import ModalEditReport from "./ModalEditReport";
import EmptyComponent from "./EmptyComponent";

function CustomerReportTable({ groupsBy: initialGroupsBy, columns: initialColumns, title: initialTitle, filters: initialFilters = {}, typeUser: initialTypeUser, onEdit, onDelete }) {
	const [dateFrom, dateTo] = useSelector(selectCompleteDate);
	const [groupsBy, setGroupsBy] = React.useState(initialGroupsBy);
	const [columns, setColumns] = React.useState(initialColumns);
	const [filters, setFilters] = React.useState(initialFilters);
	const [title, setTitle] = React.useState(initialTitle);
	const [typeUser, setTypeUser] = React.useState(_.isArray(initialTypeUser) ? initialTypeUser : [initialTypeUser]);
	const handleSubmit = (values) => {
		setGroupsBy(values.groupsBy);
		setTitle(values.title);
		setColumns(values.columns);
		setFilters(values.filters);
		setTypeUser(values.typeUser);
		onEdit(values);
	};
	const [isOpen, setIsOpen] = React.useState(false);
	const handleCloseModal = () => {
		setIsOpen(false);
	};
	const handleOpenModal = () => {
		setIsOpen(true);
	};
	const dictionary = useColumnsDictionary();

	const { data, loading } = useQuery(getContactReport, {
		variables: {
			dateFrom: dateFrom,
			dateTo: dateTo,
			groupsBy: groupsBy,
			segmentsBy: null,
			columns: columns,
			filters: filters,
			typeUser: _.isArray(typeUser) ? typeUser : [typeUser],
		},
	});
	const rows = React.useMemo(() => {
		if (!data?.reports) return [{}];
		const newRows = _.map(data?.reports, (report) => {
			// Sort fields by groupsBy first, then columns, both alphabetically
			const groupByFields = Object.entries(report)
				.filter(([key]) => groupsBy.includes(key))
				.sort((a, b) => a[0].localeCompare(b[0]));
			const columnFields = Object.entries(report)
				.filter(([key]) => columns.includes(key.split("_")[0]))
				.sort((a, b) => a[0].localeCompare(b[0]));
			const sortedFields = [...groupByFields, ...columnFields];

			// Create new object with sorted fields
			const sortedRest = Object.fromEntries(sortedFields);

			return {
				...sortedRest,
			};
		});

		return newRows;
	}, [columns, data?.reports, groupsBy]);
	const getKeyName = React.useCallback(
		(key) => {
			const splitAverage = key.split("_average");
			const splitCount = key.split("_count");
			let isPercentage = splitAverage.length > 1;
			let isCount = splitCount.length > 1;
			const currentKey = isPercentage ? splitAverage[0] : splitCount[0];
			if (isPercentage) {
				return `${dictionary[currentKey]?.label || key} (%)`;
			}
			if (isCount) {
				return `${dictionary[currentKey]?.label || key}`;
			}
			return `${dictionary[currentKey]?.label || key}`;
		},
		[dictionary]
	);

	const columnHeaders = React.useMemo(() => {
		const columns = Object.keys(rows[0] || []).map((key) => {
			let type = "string";
			if (key.indexOf("_average") > -1 || key.indexOf("_count") > -1) {
				type = "number";
			}
			return { id: key, label: getKeyName(key), type };
		});
		const columnsOrder = [];
		if (groupsBy.length > 0) {
			groupsBy.forEach((group) => {
				const colToInsert = columns.find((column) => column.id.startsWith(group));
				if (colToInsert) {
					columnsOrder.push(colToInsert);
				}
			});
		}
		if (initialColumns.length > 0) {
			initialColumns.forEach((column) => {
				const colCount	 = columns.find((c) => c.id.startsWith(`${column}_count`));
				const colAverage = columns.find((c) => c.id.startsWith(`${column}_average`));
				if (colCount) {
					columnsOrder.push(colCount);
				}
				if (colAverage) {
					columnsOrder.push(colAverage);
				}
			});
		}
		return columnsOrder;
	}, [getKeyName, groupsBy, initialColumns, rows]);
	if (loading) return null;
	if (rows.length === 0)
		return (
			<Box sx={{ width: "100%" }}>
				<ModalEditReport isOpen={isOpen} onClose={handleCloseModal} onSubmit={handleSubmit} initialValues={{ groupsBy, columns, title, filters, typeUser }} />
				<Paper sx={{ width: "100%" }}>
					<Card>
						<CardHeader
							title={title}
							action={
								<>
									<IconButton aria-label="settings" onClick={handleOpenModal}>
										<EditIcon />
									</IconButton>
									<IconButton aria-label="settings" onClick={onDelete}>
										<DeleteIcon />
									</IconButton>
								</>
							}
						/>
						<CardContent>
							<EmptyComponent />
						</CardContent>
					</Card>
				</Paper>
			</Box>
		);
	return (
		<>
			<ModalEditReport isOpen={isOpen} onClose={handleCloseModal} onSubmit={handleSubmit} initialValues={{ groupsBy, columns, title, filters, typeUser }} />

			{columnHeaders && columnHeaders.length > 0 && (
				<CustomDataGrid
					actions={[EditButtonCustomerReportTable, DeleteButtonCustomerReportTable]}
					actionsParams={{ onAdd: handleOpenModal, onDelete }}
					label={title}
					hideFooterSelectedRowCount={false}
					checkboxSelection={false}
					rows={rows}
					columns={columnHeaders}
				/>
			)}
		</>
	);
}

export default CustomerReportTable;
