import { gql, useQuery } from "@apollo/client";
import { IconButton, Stack, TextField, Tooltip } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import CloseIcon from "@mui/icons-material/Close";
import _ from "lodash";
import * as React from "react";
import { useField } from "react-final-form";
import { useEffect, useRef } from "react";
import LibraryAddCheckIcon from "@mui/icons-material/LibraryAddCheck";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};

export default function FormSelectColumnValues({ column, onChange, name, label, defaultValue, ...props }) {
	const { input, meta } = useField(name);

	const QUERY = gql`
		query getColumnValues($column: String!) {
			columns: getColumnValues(column: $column)
		}
	`;
	const { data: { columns = [] } = {} } = useQuery(QUERY, { fetchPolicy: "cache-first", variables: { column } });

	const list = React.useMemo(() => {
		const newColumns = [];
		_.forEach(columns, (column) => {
			newColumns.push({ id: column, label: column });
		});
		return newColumns;
	}, [columns]);
	const [selectedItems, setSelectedItems] = React.useState(defaultValue || []);
	const [filter, setFilter] = React.useState("");
	const [open, setOpen] = React.useState(false);
	const inputRef = useRef(null);

	const clearAll = (event) => {
		event.preventDefault();
		event.stopPropagation();
		setSelectedItems([]);
		input.onChange([]);
		if (onChange) {
			onChange([]);
		}
	};
	const handleChange = (event) => {
		if (input?.onChange) {
			input.onChange(event);
		}
		const ids = event.target.value;
		setSelectedItems(ids);
		if (onChange) {
			onChange(ids);
		}
	};

	const handleSelectAllFilters = (event) => {
		event.preventDefault();
		event.stopPropagation();
		const ids = filteredList.map((column) => column.id);
		input.onChange(ids);
		setSelectedItems(ids);
		if (onChange) {
			onChange(ids);
		}
	};

	const handleFilterChange = (event) => {
		setFilter(event.target.value);
	};

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleKeyDown = (event) => {
		if (event.key !== "Enter" && event.key !== "Tab") {
			event.stopPropagation();
		}
	};

	const filteredList = React.useMemo(() => {
		
		const listFiltered = list.filter((column) => column?.id && column?.label?.toLowerCase()?.includes(filter.toLowerCase()));
		console.log("**** listFiltered", listFiltered);
		return listFiltered;
	}, [list, filter]);

	useEffect(() => {
		if (inputRef.current) {
			inputRef.current.focus();
		}
	}, [filteredList]);

	return (
		<div>
			<FormControl sx={{ width: 290 }}>
				<InputLabel>{label}</InputLabel>
				<Stack direction="row" alignItems="center" sx={{ width: "100%" }}>
					<Select
						multiple
						input={<OutlinedInput label="Tag" />}
						renderValue={(selected) => selected.join(", ")}
						MenuProps={{ ...MenuProps, open }}
						{...props}
						{...input}
						onChange={handleChange}
						value={selectedItems}
						error={meta.error && meta.touched}
						helperText={meta?.error}
						sx={{ width: "100%" }}
						onOpen={handleOpen}
						onClose={handleClose}
						key="select">
						<MenuItem>
							<TextField
								key="filter"
								placeholder="Filtrar opciones"
								value={filter}
								onChange={handleFilterChange}
								fullWidth
								variant="outlined"
								onFocus={handleOpen}
								onKeyDown={handleKeyDown}
								inputRef={inputRef}
							/>
							<Tooltip title="Seleccionar todos los filtrados">
								<IconButton onClick={handleSelectAllFilters} size="small" sx={{ ml: 1 }}>
									<LibraryAddCheckIcon />
								</IconButton>
							</Tooltip>
						</MenuItem>
						<MenuItem disabled>
							<ListItemText primary="Opciones" />
						</MenuItem>
						{filteredList.map((column) => (
							<MenuItem key={column.id} value={column.id}>
								<Checkbox checked={selectedItems.includes(column.id)} />
								<ListItemText primary={column.label} />
							</MenuItem>
						))}
					</Select>
					<Stack sx={{ width: 20 }}>
						{selectedItems.length > 0 && (
							<Tooltip title="Limpiar">
								<IconButton onClick={clearAll} size="small" sx={{ ml: 1 }}>
									<CloseIcon />
								</IconButton>
							</Tooltip>
						)}
					</Stack>
				</Stack>
			</FormControl>
		</div>
	);
}
