import { gql } from "@apollo/client";

export const getPageConfig = gql`
	query ($code: String!) {
		pageconfig: getPageConfig(code: $code) {
			id
			code
			config
		}
	}
`;



export const upsertPageConfig = gql`
	mutation  upsertPageConfig($code: String! , $config: JSON) {
		pageConfig: upsertPageConfig(code: $code, config: $config){
			id
			code
			config
		}
	}
`;

