import { useQuery } from "@apollo/client";
import * as React from "react";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Box, Card, CardContent, CardHeader, IconButton } from "@mui/material";
import { useSelector } from "react-redux";
import { selectCompleteDate } from "../../../redux/layout";
import { CHART_TYPES } from "../constants/constants";
import { getContactReport } from "../GQLQueries";
import useChartDataProcess from "../hooks/useChartDataProcess";
import ChartBox from "./ChartBox";
import ModalEditReport from "./ModalEditReport";
import _ from "lodash";

function CustomerReportChart({ groupsBy: initialGroupsBy, columns: initialColumns, title: initialTitle, filters: initialFilters = {}, typeChart = CHART_TYPES.BAR, onDelete, onEdit, typeUser: initialTypeUser }) {
	const [dateFrom, dateTo] = useSelector(selectCompleteDate);
	const [groupsBy, setGroupsBy] = React.useState(initialGroupsBy);
	const [columns, setColumns] = React.useState(initialColumns);
	const [filters, setFilters] = React.useState(initialFilters);
	const [title, setTitle] = React.useState(initialTitle);
	const [typeUser, setTypeUser] = React.useState(_.isArray(initialTypeUser) ? initialTypeUser : [initialTypeUser]);
	const { data } = useQuery(getContactReport, {
		variables: {
			dateFrom: dateFrom,
			dateTo: dateTo,
			groupsBy: groupsBy,
			columns: columns,
			filters: filters,
			typeUser: typeUser,
		},
	});
	const handleSubmit = (values) => {
		setGroupsBy(values.groupsBy);
		setTitle(values.title);
		setColumns(values.columns);
		setFilters(values.filters);
		setTypeUser(values.typeUser);
		onEdit(values);
	};
	const { data: dataChart } = useChartDataProcess({ data: data?.reports, groupsBy, columns, typeChart });

	const options = {
		responsive: true,
		plugins: {
			legend: {
				position: "top",
			},
			title: {
				display: false,
			},
		},
	};
	const [isOpen, setIsOpen] = React.useState(false);
	const handleCloseModal = () => {
		setIsOpen(false);
	};
	const handleOpenModal = () => {
		setIsOpen(true);
	};

	return (
		<>
			<ModalEditReport isOpen={isOpen} onClose={handleCloseModal} onSubmit={handleSubmit} initialValues={{ groupsBy, columns, title, filters, typeUser }} isChart={true}/>
			<Card>
				<CardHeader
					title={title}
					action={
						<>
						<IconButton aria-label="settings" onClick={handleOpenModal}>
							<EditIcon />
						</IconButton>
						<IconButton aria-label="settings" onClick={onDelete}>
							<DeleteIcon />
						</IconButton>
						</>
					}
				/>
				<CardContent>
					<Box sx={{ height: "500px" }}>
					<ChartBox data={dataChart} options={options} typeChart={typeChart} />
					</Box>
				</CardContent>
			</Card>
		</>
	);
}

export default CustomerReportChart;
