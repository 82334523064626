import ModalFrame from "../../../components/form/ModalFrame";
import { WhatsApp } from "@mui/icons-material";

import { useDisclosure } from "react-use-disclosure";
import { Stack, Tooltip, IconButton, AvatarGroup, Avatar } from "@mui/material";
import { Form } from "react-final-form";
import { gql, useLazyQuery, useQuery } from "@apollo/client";
import FormAutocomplete from "../../../components/form/FormAutocomplete";
import TableIconButton from "../../../components/TableIconButton";

export default function SendWhatsappAction({ selectedRows = [] }) {
	const { isOpen, open, close } = useDisclosure(false);

	const GQLGetTemplates = gql`
		query GetTemplates {
			templates: getWhatsappTemplates {
				value: id
				label: name
			}
		}
	`;
	const { data: { templates } = {} } = useQuery(GQLGetTemplates);

	const GQLSendWhatsapp = gql`
		query sendWhatsapp($users: [Int], $templateId: Int!) {
			sendWhatsapp(toUsersIds: $users, templateId: $templateId)
		}
	`;

	const [sendWhatsapp] = useLazyQuery(GQLSendWhatsapp, { variables: {}, fetchPolicy: "network-only" });

	const validate = (values) => {
		const errors = {};
		if (!values.templateId) errors.templateId = "Required";
		return errors;
	};

	return (
		<>
			<TableIconButton onClick={open} disabled={selectedRows.length < 1} icon={<WhatsApp />} tooltip="Enviar whatsapp" />
			<Form
				onSubmit={async (values) => {
					sendWhatsapp({ variables: values });
					close();
				}}
				initialValues={{ templateId: null, users: selectedRows.map((t) => t.owner.id) }}
				validate={validate}
				render={({ handleSubmit }) => (
					<ModalFrame
						onSubmit={handleSubmit}
						{...{ isOpen, close }}
						title={
							<>
								Enviando whatsapp a:
								<AvatarGroup style={{ padding: "0 20px", display: "inline-flex" }} total={selectedRows.length}>
									{selectedRows
										.filter((_, i) => i < 5)
										.map((row) => (
											<Avatar alt={`${row.owner?.firstName} ${row.owner?.lastName}`} src={row.owner?.profilePicture} />
										))}
								</AvatarGroup>
							</>
						}
						buttonTexts={["Enviar", "Cancelar"]}>
						<Stack spacing={4}>
							<FormAutocomplete required optionValueKey="value" options={templates} style={{ width: "100%" }} name="templateId" label="Template" type="text" autoFocus maxToShow={null}/>
						</Stack>
					</ModalFrame>
				)}
			/>
		</>
	);
}
