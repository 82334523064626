import React, { useState } from 'react';
import {
  Box,
  Card,
  Typography,
  Stack,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Chip
} from '@mui/material';
import { gql, useQuery, useMutation } from '@apollo/client';
import { useSelector } from 'react-redux';
import { CustomDataGrid, CustomDateRangePicker } from '../../../components';
import CustomFilterPanelForTable from '../../../components/customFilterPanelForTable';
import { selectCompleteDate } from '../../../redux/layout';
import { isoDateRenderer, priceRenderer } from '../../../tableUtils';
import CobroExpressForm from './CobroExpressForm';
import { Add as AddIcon, Cancel as CancelIcon, Edit as EditIcon } from '@mui/icons-material';
import TableIconButton from '../../../components/TableIconButton';
import { STATUS } from '../constants';

const GET_COBROS_EXPRESS = gql`
  query getCobrosExpress($input: CobroExpressInput!) {
    getCobrosExpress(input: $input) {
      items {
        id
        createdAt
        firstName
        lastName
        documentNumber
        phone
        email
        debtAmount
        status
        notes
        paymentAt
        cancellationAt
      }
      totalCount
    }
  }
`;

const CANCEL_COBRO_EXPRESS = gql`
  mutation cancelCobroExpress($id: Int!) {
    cancelCobroExpress(id: $id) {
      id
      status
      cancellationAt
    }
  }
`;

const CancelCobroAction = ({ selectedRows, reload }) => {
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [cancelCobroExpress] = useMutation(CANCEL_COBRO_EXPRESS);

  const handleCancel = async () => {
    if (!selectedRows || selectedRows.length === 0) return;
    setConfirmOpen(true);
  };

  const confirmCancel = async () => {
    try {
      for (const row of selectedRows) {
        await cancelCobroExpress({
          variables: { id: parseInt(row.id) }
        });
      }
      
      setConfirmOpen(false);
      if (reload) reload();
    } catch (error) {
      console.error("Error al cancelar cobros:", error);
      setConfirmOpen(false);
    }
  };

  const allSelectedArePending = selectedRows && 
                               selectedRows.length > 0 && 
                               selectedRows.every(row => row.status === 'PENDING');

  return (
    <>
      <TableIconButton
        onClick={handleCancel}
        icon={<CancelIcon />}
        tooltip="Cancelar cobros seleccionados"
        disabled={!allSelectedArePending}
      />
      
      <Dialog open={confirmOpen} onClose={() => setConfirmOpen(false)}>
        <DialogTitle>Confirmar cancelación</DialogTitle>
        <DialogContent>
          ¿Está seguro que desea cancelar {selectedRows?.length} cobro(s)?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmOpen(false)}>No</Button>
          <Button onClick={confirmCancel} color="error" variant="contained">
            Sí, cancelar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default function CobroExpressTable() {
  const [openForm, setOpenForm] = useState(false);
  const [selectedCobro, setSelectedCobro] = useState(null);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(50);
  // eslint-disable-next-line no-unused-vars
  const [filters, setFilters] = useState({});
  
  const [dateFrom, dateTo] = useSelector(selectCompleteDate);
  
  const { data, loading, refetch } = useQuery(GET_COBROS_EXPRESS, {
    variables: {
      input: {
        page: page + 1,
        limit: limit,
        dateFrom,
        dateTo,
        ...filters
      }
    },
    fetchPolicy: 'network-only'
  });
  
  const cobros = data?.getCobrosExpress?.items || [];
  const totalCount = data?.getCobrosExpress?.totalCount || 0;

  const handleNew = () => {
    setSelectedCobro(null);
    setOpenForm(true);
  };
  
  const handleEdit = (cobro) => {
    if (!cobro) return; 
    
    setSelectedCobro({
      id: cobro.id,
      firstName: cobro.firstName || '',
      lastName: cobro.lastName || '',
      documentNumber: cobro.documentNumber || '',
      phone: cobro.phone || '',
      email: cobro.email || '',
      debtAmount: cobro.debtAmount || 0,
      status: cobro.status || '',
      notes: cobro.notes || '',
      paymentAt: cobro.paymentAt || null,
      cancellationAt: cobro.cancellationAt || null
    });
    setOpenForm(true);
  };
  
  const handleCloseForm = () => {
    setOpenForm(false);
    refetch();
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (newLimit) => {
    setLimit(newLimit);
    setPage(0);
  };

  const renderEstado = (row) => {
    const textoEstado = STATUS[row.status] || row.status;
    const chipColor = row.status === 'CANCELED' ? 'error' 
      : row.status === 'PAID' ? 'success'
      : 'warning';

    return (
      <Chip 
        label={textoEstado}
        color={chipColor}
        size="small"
        variant="filled"
      />
    );
  };
  
  const columns = [
    { id: 'id', label: 'ID', type: 'number' },
    { id: 'createdAt', label: 'Fecha de Creación', ...isoDateRenderer('createdAt') },
    { id: 'firstName', label: 'Nombre' },
    { id: 'lastName', label: 'Apellido' },
    { id: 'documentNumber', label: 'DNI' },
    { id: 'phone', label: 'Teléfono' },
    { id: 'email', label: 'Email' },
    { id: 'debtAmount', label: 'Importe', ...priceRenderer('debtAmount') },
    { 
      id: 'status', 
      label: 'Estado', 
      customRender: renderEstado,
      customValue: (row) => STATUS[row.status] || row.status
    },
    { id: 'notes', label: 'Notas', customRender: (row) => row.notes || '' },
    { id: 'paymentAt', label: 'Fecha de Pago', ...isoDateRenderer('paymentAt')  },
    { id: 'cancellationAt', label: 'Fecha de Cancelación', ...isoDateRenderer('cancellationAt') }
  ];
  
  const NewCobroAction = () => (
    <TableIconButton 
      onClick={handleNew}
      icon={<AddIcon />}
      tooltip="Nuevo Cobro Express"
    />
  );
  
  const EditCobroAction = ({ selectedRows, onEdit }) => {
    const handleEditClick = () => {
      if (selectedRows?.length === 1) {
        onEdit(selectedRows[0]);
      }
    };

    return (
      <TableIconButton 
        onClick={handleEditClick}
        icon={<EditIcon />}
        tooltip="Editar Cobro Express"
        disabled={!selectedRows || 
                  selectedRows.length !== 1 || 
                  selectedRows[0]?.status !== 'PENDING'}
      />
    );
  };
  
  return (
    <>
      <Stack direction="row" justifyContent="space-between">
        <Typography sx={{ m: 1 }} variant="h4">
          Cobro Express
        </Typography>
        <CustomDateRangePicker />
      </Stack>
      
      <Card>
        <Box sx={{ minWidth: 1050 }}>
          <CustomDataGrid
            filterPanel={CustomFilterPanelForTable}
            reload={refetch}
            loading={loading}
            actions={[
              NewCobroAction,
              (props) => <EditCobroAction {...props} onEdit={handleEdit} />,
              CancelCobroAction
            ]}
            hideFooterSelectedRowCount
            checkboxSelection
            rows={cobros}
            columns={columns}
            count={totalCount}
            page={page}
            rowsPerPage={limit}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleLimitChange}
          />
        </Box>
      </Card>
      
      {openForm && (
        <CobroExpressForm
          open={openForm}
          cobro={selectedCobro}
          onClose={handleCloseForm}
        />
      )}
    </>
  );
}
