export const CHART_TYPES = {
    BAR: 'bar',
    DOUGHNUT: 'doughnut',
    COLUMN: 'column',
    LINE: 'line',
    PIE: 'pie',
    RADAR: 'radar',
    LIST: 'list',
    PROGRESS: 'progress',
    MULTIPLE_PROGRESS: 'multipleProgress'
  };
  
  // eslint-disable-next-line import/no-anonymous-default-export
  export default {
    CHART_TYPES
  };
  