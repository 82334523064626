import _ from "lodash";
import { useMemo } from "react";
import { CHART_TYPES } from "../constants/constants";
import { amber, blue, blueGrey, brown, cyan, deepOrange, deepPurple, green, grey, indigo, lightBlue, lightGreen, lime, orange, pink, purple, red, teal, yellow } from "@mui/material/colors";
import useColumnsDictionary from "./useColumnsDictionary";

const materialColors = [red, pink, purple, deepPurple, indigo, blue, lightBlue, cyan, teal, green, lightGreen, lime, yellow, amber, orange, deepOrange, brown, grey, blueGrey];
const indexColors = ["50", "100", "200", "300", "400", "500", "600", "700", "800", "900", "A100", "A200", "A400", "A700"];

/**
 *
 */
function useChartDataProcess({ data, groupsBy, columns, typeChart }) {
	const getChartColors = (countColorsNeed, startIndex = 0, startVariationIndex = 5) => {
		const totalColorsAvailable = materialColors.length;
		const totalVariationIndex = indexColors.length;
		let currentIndex = startIndex;
		let currentVariationIndex = startVariationIndex;
		let backColors = [];

		for (let i = 0; i < countColorsNeed; i++) {
			const color = materialColors[currentIndex][indexColors[currentVariationIndex]];
			backColors.push(color);
			currentIndex += 1;
			if (currentIndex >= totalColorsAvailable) {
				currentIndex = 0;
				currentVariationIndex += 1;
				if (currentVariationIndex >= totalVariationIndex) {
					currentVariationIndex = 0;
				}
			}
		}

		return backColors;
	};
	const dictionary = useColumnsDictionary();
	

	const dataChart = useMemo(() => {
		if (data?.length === 0) return null;

		const principalGroupFieldName = groupsBy[0];
		const secondaryGroupFieldName = groupsBy[1];
		const isColumnsInDatasets = secondaryGroupFieldName === undefined;
		let secondaryGroupVec = [];
		const principalGroupIds = new Set(_.map(data, (item) => item[principalGroupFieldName]));
		const principalGroupVec = Array.from(principalGroupIds);
		if (!isColumnsInDatasets) {
			const secondaryGroupIds = new Set(_.map(data, (item) => item[secondaryGroupFieldName]));
			secondaryGroupVec = Array.from(secondaryGroupIds);
		} else {
			secondaryGroupVec = columns;
		}
		let colors = getChartColors(principalGroupVec.length);
		let oneColorByDataset = true;
		if (groupsBy.length === 1 && columns.length === 1) {
			colors = getChartColors(1);
		} else {
			colors = getChartColors(secondaryGroupVec.length);
		}
		const newData = {
			labels: principalGroupVec,
			datasets: _.map(secondaryGroupVec, (column, index) => {
				return {
					label: isColumnsInDatasets ? dictionary[column].label : column,
					data: Array(principalGroupVec.length).fill(0),
					backgroundColor: oneColorByDataset ? colors[index] : colors,
					borderColor: oneColorByDataset ? colors[index] : colors,
				};
			}),
		};
		_.forEach(data, (item) => {
			const indexPrincipalGroup = principalGroupVec.indexOf(item[principalGroupFieldName]);
			if (isColumnsInDatasets) {
				_.forEach(secondaryGroupVec, (column, index) => {
					newData.datasets[index].data[indexPrincipalGroup] = parseFloat(item[`${column}_count`]);
				});
			} else {
				const indexSecondaryGroup = secondaryGroupVec.indexOf(item[secondaryGroupFieldName]);
				const column = columns[0];
				newData.datasets[indexSecondaryGroup].data[indexPrincipalGroup] = parseFloat(item[`${column}_count`]);
			}
		});
		return newData;
	}, [columns, data, dictionary, groupsBy]);

	const options = useMemo(() => {
		// const secondaryGroupFieldName = group === 1 ? "groupTwo" : "groupOne";
		// const secondaryGroupIds = new Set(_.map(data, (item) => item[`${secondaryGroupFieldName}_id`]));
		// const principalGroupFieldName = group === 1 ? "groupOne" : "groupTwo";
		// const principalGroupIds = new Set(_.map(data, (item) => item[`${principalGroupFieldName}_id`]));
		// const defaultOptions = {
		// 	responsive: true,
		// 	plugins: {
		// 		legend: getLegendConfig(principalGroupIds, secondaryGroupIds),
		// 	},
		// };
		// return { ...defaultOptions };
		return {};
	}, []);
	return { data: dataChart, options };
}

export default useChartDataProcess;
