import { TextField } from "@mui/material";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import React from "react";
import { useField } from "react-final-form";
import GoogleMapsAutocomplete from "../GoogleMapsAutocomplete";

export default function FormAddress({ name, ...props }) {
	const { isLoaded } = useJsApiLoader({
		id: process.env.GOOGLE_MAPS_ID,
		googleMapsApiKey: process.env.GOOGLE_MAPS_API_KEY,
		libraries: ["visualization"]
	});

	const { input } = useField(name);
	const [value, setValue] = React.useState(input.value);

	const onChangePlace = (newPlace) => {
		setValue((v) => {
			let newValue = { ...v };
			if (!newPlace?.inline) {
				input.onChange({ apartment: v.apartment });
				return { apartment: v.apartment };
			}

			newValue.street = newPlace?.terms[0]?.value;
			newValue.number = newPlace?.terms[1]?.value;
			newValue.locality = newPlace?.terms[2]?.value;
			newValue.state = newPlace?.terms[3]?.value;
			newValue.postalCode = newPlace?.address_components?.find((c) => c.types.includes("postal_code"))?.long_name;
			newValue.lat = newPlace?.geometry?.location?.lat();
			newValue.lng = newPlace?.geometry?.location?.lng();
			newValue.inline = newPlace?.formatted_address;
			input.onChange(newValue);
			return newValue;
		});
	};

	const onChangeFloor = (e) => {
		setValue((v) => ({ ...v, apartment: e.target.value }));
		input.onChange({ ...value, apartment: e.target.value });
	};

	return (
		<div
			style={{
				display: "flex",
				flexDirection: "column",
				gap: 10,
				columnGap: 10,
			}}>
			<div
				style={{
					gap: 10,
					display: "flex",
					flexDirection: "row",
					width: "100%",
				}}>
				<GoogleMapsAutocomplete
					style={{
						display: "flex",
						width: "100%",
					}}
					onChangePlace={onChangePlace}
					initialValue={value}
				/>
				<TextField 
					onChange={onChangeFloor} 
					label="Piso/Dpto" 
					value={value?.apartment} 
				/>
			</div>
			<div style={styles.addressDetails}>
				<span>Calle:{value.street}</span>
				<span>Número:{value.number}</span>
				<span>Localidad:{value.locality}</span>
				<span>Provincia:{value.state}</span>
				<span>Código postal:{value.postalCode}</span>
			</div>
			{isLoaded && !!value.lat && !!value.lng && (
				<GoogleMap
					options={{
						mapId: "1ea02683e0a5f5da",
						disableDefaultUI: true,
						draggingCursor: false,
						gestureHandling: "greedy",
						clickableIcons: false,
						mapTypeControl: false,
					}}
					mapContainerStyle={{ width: "100%", height: "300px", borderRadius: 10 }}
					center={{ lng: value.lng, lat: value.lat }}
					zoom={16}>
					<Marker
						clickable={false}
						position={{
							lng: value.lng,
							lat: value.lat,
						}}
					/>
				</GoogleMap>
			)}
		</div>
	);
}

const styles = {
	addressDetails: {
		gap: 10,
		display: "flex",
		width: "100%",
		borderBottomLeftRadius: 10,
		borderBottomRightRadius: 10,
		// alignItems: "center",
		justifyContent: "space-around",
		fontSize: 14,
		padding: 5,
		color: "#444",
		paddingTop: 10,
		marginTop: -5,
	},
};
