import { useQuery } from "@apollo/client";
import Alert from "@mui/material/Alert";
import React, { useRef, forwardRef, useImperativeHandle } from "react";
import { getLastImportCsvStatus } from "./../GQLQueriesStatus";
import ModalUploadResult from "./ModalUploadResult";
import { Button } from "@mui/material";
const STATUS_IMPORT_CSV = {
	COMPLETED: "COMPLETED",
	IN_PROGRESS: "IN_PROGRESS",
	ERROR: "ERROR",
	CANCELED: "CANCELED",
};

const formatDateTime = (dateString) => {
	const date = new Date(dateString);
	return date.toLocaleString("es-ES", {
		year: 'numeric',
		month: 'long',
		day: 'numeric',
		hour: '2-digit',
		minute: '2-digit',
	});
};

const ImporCsvStatus = forwardRef((props, ref) => {
	const { data: dataLastImportCsvStatus, loading: loadingLastImportCsvStatus, refetch } = useQuery(getLastImportCsvStatus);
	const hastErros = dataLastImportCsvStatus?.importCsvStatus?.error?.length > 0;
	const modalUploadResultRef = useRef(null);
    const formattedDateTime = formatDateTime(dataLastImportCsvStatus?.importCsvStatus?.createdAt);

	useImperativeHandle(ref, () => ({
		refetchData: () => {
			refetch();
		},
	}));

	if (loadingLastImportCsvStatus) return null;
	if (!dataLastImportCsvStatus?.importCsvStatus) {
		return <Alert severity="info">No hay ninguna importación de CSV</Alert>;
	}
	if (dataLastImportCsvStatus.importCsvStatus.status === STATUS_IMPORT_CSV.COMPLETED && !hastErros) {
		
		return <Alert severity="success">{formattedDateTime}: Importación de CSV exitosa</Alert>;
	}
	if (dataLastImportCsvStatus.importCsvStatus.status === STATUS_IMPORT_CSV.COMPLETED && hastErros) {
		return (
			<>
				<Alert
					severity="warning"
					action={
						<Button color="inherit" size="small" onClick={() => modalUploadResultRef.current.open()}>
							Ver errores
						</Button>
					}>
					{formattedDateTime}: Importación de CSV exitosa con errores
				</Alert>
				<ModalUploadResult errors={dataLastImportCsvStatus.importCsvStatus.error} ref={modalUploadResultRef} />
			</>
		);
	}
	if (dataLastImportCsvStatus.importCsvStatus.status === STATUS_IMPORT_CSV.ERROR) {
		return <Alert severity="error">{formattedDateTime}: Error al importar CSV</Alert>;
	}
	if (dataLastImportCsvStatus.importCsvStatus.status === STATUS_IMPORT_CSV.IN_PROGRESS) {
		return <Alert severity="info">{formattedDateTime}: Importación de CSV en progreso</Alert>;
	}

	return <Alert severity="info">Importación de CSV cancelada - Fecha: {formattedDateTime}</Alert>;
});

export default ImporCsvStatus;
