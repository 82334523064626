import { WhatsApp } from "@mui/icons-material";
import { gql, useLazyQuery, useQuery } from "@apollo/client";
import { Stack } from "@mui/material";
import { Form } from "react-final-form";
import { useDisclosure } from "react-use-disclosure";
import TableIconButton from "./TableIconButton";
import FormAutocomplete from "./form/FormAutocomplete";
import ModalFrame from "./form/ModalFrame";

export default function SendWhatsappByPackageAction({ targetUsers, icon, tooltip, title }) {
	const { isOpen, open, close } = useDisclosure(false);

	const GQLGetTemplates = gql`
		query GetTemplates {
			templates: getWhatsappTemplates {
				value: id
				label: name
			}
		}
	`;
	const { data: { templates } = {} } = useQuery(GQLGetTemplates);

	const GQLSendWhatsappByPackage = gql`
		query sendWhatsappByPackage($packageIds: [Int], $templateId: Int!) {
			sendWhatsappByPackage(packageIds: $packageIds, templateId: $templateId)
		}
	`;

	const [sendWhatsappByPackage] = useLazyQuery(GQLSendWhatsappByPackage, { variables: {}, fetchPolicy: "network-only" });

	const validate = (values) => {
		const errors = {};
		if (!values.templateId) errors.templateId = "Required";
		return errors;
	};

	return (
		<>
			<TableIconButton onClick={open} disabled={targetUsers < 1} icon={icon} tooltip={tooltip} />
			<Form
				onSubmit={async (values) => {
					sendWhatsappByPackage({ 
						variables: { 
							packageIds: values.users, 
							templateId: values.templateId 
						} 
					});
					close();
				}}
				initialValues={{ templateId: null, users: targetUsers }}
				validate={validate}
				render={({ handleSubmit }) => (
					<ModalFrame onSubmit={handleSubmit} {...{ isOpen, close }} title={title} buttonTexts={["Enviar", "Cancelar"]}>
						<Stack spacing={4}>
							<FormAutocomplete required optionValueKey="value" options={templates} style={{ width: "100%" }} name="templateId" label="Template" type="text" autoFocus maxToShow={null} />
						</Stack>
					</ModalFrame>
				)}
			/>
		</>
	);
}

export const defaultTargetUserGetter = (selectedRows) => selectedRows.map((u) => u.id); // This only works on users reports
const defaultOptions = {
	tooltip: "Enviar notificacion por Whatsapp",
	icon: <WhatsApp />,
};

export function WhatsappActionByPackageFactory(targetUsersGetter = defaultTargetUserGetter, options = {}) {
	return function WhatsappAction({ selectedRows = [] }) {
		let targetUsers = targetUsersGetter(selectedRows);
		if (!Array.isArray(targetUsers)) targetUsers = [];
		// Remove duplicated targetUsers
		targetUsers = [...new Set(targetUsers)];

		const actionProps = { targetUsers, ...defaultOptions, ...options };
		if (!options.title) actionProps.title = `Enviando whatsapp a ${selectedRows.length} usuarios`;

		return <SendWhatsappByPackageAction {...actionProps} />;
	};
}
