import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import _ from 'lodash';
import * as React from 'react';
import useColumnsDictionary from '../hooks/useColumnsDictionary';
import { useField } from 'react-final-form';
import { FormHelperText } from '@mui/material';



export default function FormSelectReportGroupBy({onChange,name,defaultValue, ...props}) {
  
  const { input, meta,  } = useField(name);
    const dictionary = useColumnsDictionary();
    const groups = React.useMemo(() => {
        const newGroups = [];
        _.forEach(dictionary, (group,key    ) => {
            if (group.groups) {
                newGroups.push({id:key,label:group.label});
            }
        });
        return newGroups;
    }, [dictionary])
  const [selectedGroups, setSelectedGroups] = React.useState(defaultValue || []);

  const handleChange = (event) => {
    if (input?.onChange) {
      input.onChange(event);
    }
    const ids = event.target.value;
    setSelectedGroups(ids);
    if (onChange) {
        onChange(ids);
    }
  };

  return (
    <div>
      <FormControl sx={{ width: 290 }}>
        <InputLabel id="demo-multiple-checkbox-label">Agrupar por</InputLabel>
        <Select
          multiple
          
          input={<OutlinedInput label="Tag" />}
          renderValue={(selected) => selected.map(id => groups.find(group => group.id === id).label).join(', ')}
          
          {...props}
          {...input}
          onChange={handleChange}
          value={selectedGroups}
          error={meta.error && meta.touched}
          helperText={meta?.error}
        >
          {groups.map((group) => (
            <MenuItem key={group.id} value={group.id}>
             <Checkbox checked={selectedGroups.includes(group.id)} />
              <ListItemText primary={group.label} />
            </MenuItem>
          ))}
        </Select>
        {meta.error && meta.touched && <FormHelperText error={meta.error && meta.touched}>{meta?.error}</FormHelperText>}
      </FormControl>
    </div>
  );
}
