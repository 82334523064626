import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Grid,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  CircularProgress
} from '@mui/material';
import { gql, useMutation } from '@apollo/client';
import { STATUS } from '../constants';

const CREATE_COBRO_EXPRESS = gql`
  mutation createCobroExpress($input: JSONObject!) {
    createCobroExpress(input: $input) {
      id
      createdAt
      firstName
      lastName
      documentNumber
      phone
      email
      debtAmount
      status
      notes
      paymentAt
      cancellationAt
    }
  }
`;

const UPDATE_COBRO_EXPRESS = gql`
  mutation updateCobroExpress($id: Int!, $input: JSONObject!) {
    updateCobroExpress(id: $id, input: $input) {
      id
      createdAt
      firstName
      lastName
      documentNumber
      phone
      email
      debtAmount
      status
      notes
      paymentAt
      cancellationAt
    }
  }
`;

const CobroExpressForm = ({ open, cobro, onClose }) => {
  const isEdit = Boolean(cobro);
  
  const initialFormData = {
    firstName: cobro?.firstName || '',
    lastName: cobro?.lastName || '',
    documentNumber: cobro?.documentNumber || '',
    phone: cobro?.phone || '',
    email: cobro?.email || '',
    debtAmount: cobro?.debtAmount || '',
    status: cobro?.status || 'PENDING',
    notes: cobro?.notes || ''
  };

  const [formData, setFormData] = useState(initialFormData);
  const [errors, setErrors] = useState({});

  const [createCobroExpress] = useMutation(CREATE_COBRO_EXPRESS);
  const [updateCobroExpress] = useMutation(UPDATE_COBRO_EXPRESS);

  const loading = createCobroExpress.loading || updateCobroExpress.loading;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
    
    if (errors[name]) {
      setErrors({
        ...errors,
        [name]: null
      });
    }
  };

  const validate = () => {
    const newErrors = {};
    
    if (!formData.firstName) newErrors.firstName = 'First name is required';
    if (!formData.lastName) newErrors.lastName = 'Last name is required';
    if (!formData.documentNumber) newErrors.documentNumber = 'Document number is required';
    if (!formData.phone) newErrors.phone = 'Phone is required';
    if (!formData.email) newErrors.email = 'Email is required';
    if (formData.email && !/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = 'Invalid email';
    }
    if (!formData.debtAmount) newErrors.debtAmount = 'Amount is required';
    if (isNaN(Number(formData.debtAmount))) {
      newErrors.debtAmount = 'Amount must be a number';
    }
    
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    if (!validate()) {
      return;
    }
    
    const inputData = { ...formData };
    
    try {
      if (cobro) {
        await updateCobroExpress({
          variables: {
            id: parseInt(cobro.id),
            input: inputData
          }
        });
      } else {
        await createCobroExpress({
          variables: {
            input: inputData
          }
        });
      }
      
      onClose(true);
    } catch (error) {
      console.error("Error saving payment:", error);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        {isEdit ? 'Editar Cobro Express' : 'Nuevo Cobro Express'}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Nombre"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              error={Boolean(errors.firstName)}
              helperText={errors.firstName}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Apellido"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              error={Boolean(errors.lastName)}
              helperText={errors.lastName}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="DNI"
              name="documentNumber"
              value={formData.documentNumber}
              onChange={handleChange}
              error={Boolean(errors.documentNumber)}
              helperText={errors.documentNumber}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Teléfono"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              error={Boolean(errors.phone)}
              helperText={errors.phone}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Email"
              name="email"
              type="email"
              value={formData.email}
              onChange={handleChange}
              error={Boolean(errors.email)}
              helperText={errors.email}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Importe"
              name="debtAmount"
              type="number"
              value={formData.debtAmount}
              onChange={handleChange}
              error={Boolean(errors.debtAmount)}
              helperText={errors.debtAmount}
              InputProps={{
                startAdornment: '$'
              }}
            />
          </Grid>
          {isEdit && (
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel>Estado</InputLabel>
                <Select
                  name="status"
                  value={formData.status}
                  onChange={handleChange}
                  label="Estado"
                >
                  <MenuItem value="PENDING">{STATUS.PENDING}</MenuItem>
                  <MenuItem value="PAID">{STATUS.PAID}</MenuItem>
                  <MenuItem value="CANCELED">{STATUS.CANCELED}</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          )}
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Notas"
              name="notes"
              value={formData.notes}
              onChange={handleChange}
              multiline
              rows={4}
              placeholder="Ingrese notas adicionales aquí..."
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={loading}>
          Cancelar
        </Button>
        <Button 
          onClick={handleSubmit} 
          variant="contained" 
          color="primary"
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} /> : 'Guardar'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CobroExpressForm;
