import ModalFrame from "../../../components/form/ModalFrame";
import { Email } from "@mui/icons-material";

import { useDisclosure } from "react-use-disclosure";
import { Stack, Tooltip, IconButton, AvatarGroup, Avatar } from "@mui/material";
import { Form } from "react-final-form";
import { gql, useMutation, useQuery } from "@apollo/client";
import FormAutocomplete from "../../../components/form/FormAutocomplete";
import TableIconButton from "../../../components/TableIconButton";
import { selectCompleteDate } from "../../../redux/layout";
import { useSelector } from "react-redux";

export default function SendMailAction({ selectedRows = [] }) {
	const [dateFrom, dateTo] = useSelector(selectCompleteDate);
	const { isOpen, open, close } = useDisclosure(false);

	const GQLGetEmailTemplates = gql`
		query getEmailTemplates {
			getEmailTemplates(prefix: "FINANZAS_") {
				value: id
				label: name
			}
		}
	`;
	const { data: { getEmailTemplates: emailTemplates = [] } = {} } = useQuery(GQLGetEmailTemplates);

	const GQLSendMail = gql`
		mutation SendFinancialPackagesMail($users: [JSONObject]!, $templateId: String!, $dateFrom: String, $dateTo: String) {
			sendFinancialPackagesMail(users: $users, templateId: $templateId, dateFrom: $dateFrom, dateTo: $dateTo)
		}
	`;

	const [SendMailWithTemplate] = useMutation(GQLSendMail, { variables: { dateFrom, dateTo }, fetchPolicy: "network-only" });

	const validate = (values) => {
		const errors = {};
		if (!values.templateId) errors.templateId = "Required";
		return errors;
	};

	return (
		<>
			<TableIconButton onClick={open} disabled={selectedRows.length < 1} icon={<Email />} tooltip="Enviar notificaion por mail a los expertos que trabajaron en los paquetes seleccionados" />
			<Form
				onSubmit={async (values) => {
					await SendMailWithTemplate({ variables: values });
					close();
				}}
				initialValues={{ templateId: null, users: selectedRows }}
				validate={validate}
				render={({ handleSubmit }) => (
					<ModalFrame
						onSubmit={handleSubmit}
						{...{ isOpen, close }}
						title={
							<>
								Enviando mail a los expertos que trabajaron en los paquetes seleccionados:
								<AvatarGroup style={{ padding: "0 20px", display: "inline-flex" }} total={selectedRows.length}>
									{selectedRows
										.filter((_, i) => i < 5)
										.map((row) => (
											<Avatar alt={`${row.owner?.firstName} ${row.owner?.lastName}`} src={row.owner?.profilePicture} />
										))}
								</AvatarGroup>
							</>
						}
						buttonTexts={["Enviar", "Cancelar"]}>
						<Stack spacing={4}>
							<FormAutocomplete required optionValueKey="value" options={emailTemplates} style={{ width: "100%" }} name="templateId" label="Template" type="text" autoFocus maxToShow={null}/>
						</Stack>
					</ModalFrame>
				)}
			/>
		</>
	);
}
