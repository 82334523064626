import { Button, CircularProgress } from "@mui/material";
import React, { useState } from "react";

export default function UploadCSVButton({reload}) {
	
	const [loading, setLoading] = useState(false);
	
	const handleUpload = async (file) => {
		try {
			reload();
			setLoading(true);
			const formData = new FormData();
			formData.append("file", file);
			const token = window.localStorage.getItem("sessionToken");
			const response = await fetch(`${process.env.REACT_APP_API_URI}/api/contacts/upload-csv`, {
				method: "POST",
				headers: { Authorization: `Bearer ${token}` },
				body: formData,
			});
			setLoading(false);
			if (!response.ok) {
				throw new Error(`HTTP error! status: ${response.status}`);
			}
			await response.json();
			reload();
			return null;
		} catch (err) {
			console.error("Error uploading CSV:", err);
			throw err;
		}
	};
	const onFileChange = async (event) => {
		const file = event.target.files[0];
		if (file) {
			try {
				 await handleUpload(file);
				
			} catch (err) {
				console.error("Upload failed:", err);
			}
		}
	};

	return (
		<Button variant="contained" component="label" disabled={loading}>
			{loading && <CircularProgress />}
			{loading ? "Subiendo..." : "Subir CSV"}
			<input type="file" hidden onChange={onFileChange} />
			
		</Button>
	);
}
