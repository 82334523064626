export function useColumnsDictionary() {
	const dictionary = {
		total: {
			label: "Total",
			groups: false,
			columns: true,
		},

		service: {
			label: "Servicio",
			groups: true,
			columns: false,
		},
		province: {
			label: "Provincia",
			groups: true,
			columns: false,
		},
		city: {
			label: "Ciudad",
			groups: true,
			columns: false,
		},
		mannoCity: {
			label: "Ciudad Manno",
			groups: true,
			columns: false,
		},
		channel: {
			label: "Canal",
			groups: true,
			columns: false,
		},
		alliance: {
			label: "Alianza",
			groups: true,
			columns: false,
		},
		
		branch: {
			label: "Sucursal",
			groups: true,
			columns: false,
		},
		sellerLedger: {
			label: "Legajo Vendedor",
			groups: true,
			columns: false,
		},
        survey: {
			label: "Encuesta",
			groups: true,
			columns: false,
		},
		botExperience: {
			label: "Experiencia bot",
			groups: true,
			columns: false,
		},
		installerAttention: {
			label: "Atención del instalador",
			groups: true,
			columns: false,
		},
		advisorAttention: {
			label: "Atención del asesor",
			groups: true,
			columns: false,
		},
		generalExperienceWithManno: {
			label: "Experiencia general con Manno",
			groups: true,
			columns: false,
		},
		wouldRehire: {
			label: "Volvería a contratar",
			groups: true,
			columns: false,
		},
		saleCompleted: {
			label: "Concretado",
			color: "green",
			columns: true,
		},
		abandoned: {
			label: "Abandonado",
			color: "red",
			columns: true,
		},
		saleWithInstallation: {
			label: "Con Instalación",
			color: "blue",
			columns: true,
		},
		deciding: {
			label: "Decidiendo",
			color: "yellow",
			columns: true,
		},
	};
	return dictionary;
}
export default useColumnsDictionary;
