import ModalFrame from "../../../components/form/ModalFrame";

import { Stack, Typography } from "@mui/material";
import _ from "lodash";
import { useEffect, useRef, useState } from "react";
import { Form } from "react-final-form";
import useColumnsDictionary from "../hooks/useColumnsDictionary";
import FormSelectColumnValues from "./FormSelectColumnValues";
import FormSelectReportColumns from "./FormSelectReportColumns";
import FormSelectReportGroupBy from "./FormSelectReportGroupBy";
import FormInput from "../../../components/form/FormInput";

export default function ModalEditReport({ onSubmit, initialValues, isOpen, onClose, isChart = false }) {
	const validate = (values) => {
		const errors = {};
		if (!values.title) errors.title = "Campo requerido";
		if (!values.groupsBy || values.groupsBy.length === 0) errors.groupsBy = "Campo requerido";
		if (!values.columns || values.columns.length === 0) errors.columns = "Campo requerido";
		if (isChart){
			if (values?.groupsBy?.length > 2 ) errors.groupsBy = "Hasta dos grupos pueden ser seleccionados";
			if (values?.groupsBy?.length > 1 && values?.columns?.length > 1) errors.groupsBy = "Con dos groupos solo puede elegir una columna";		
		} 
		return errors;
	};
	const dictionary = useColumnsDictionary();
	const [groups, setGroups] = useState([]);
	useEffect(() => {
		if (initialValues?.groupsBy) {
			setGroups(initialValues.groupsBy);
		}
	}, [initialValues, initialValues?.groupsBy]);
	const handleSubmitForm = (values) => {
		const valuesToSubmit = values;
		_.forEach(valuesToSubmit.filters, (value, key) => {
			const groupsBy = valuesToSubmit.groupsBy;
			const index = groupsBy.indexOf(key);
			if (index < 0) {
				valuesToSubmit.filters[key] = undefined;
			}
		});
		onSubmit(valuesToSubmit);
		onClose();
	};
	const formRef = useRef(null);
	const handleChangeGroupBy = (values) => {
		setGroups(values);
	};

	return (
		<>
			<Form
				onSubmit={handleSubmitForm}
				initialValues={initialValues}
				ref={formRef}
				validate={validate}
				render={({ handleSubmit, values, valid }) => (
					<ModalFrame
						onSubmit={handleSubmit}
						isOpen={isOpen}
						width="80%"
						close={onClose}
						title={
							<Stack direction={"column"} gap={2}>
								<span>Configuración de reportes</span>
							</Stack>
						}
						submitDisabled={!valid}
						buttonTexts={["Aceptar", "Cancelar"]}>
						<Stack spacing={2}>
							<Stack direction={"row"} gap={2}>
								<FormInput name="title" label="Titulo" type="text" />
								<FormSelectColumnValues key="typeUser" label={"Tipo de usuario"} name={`typeUser`} column={"typeUser"} defaultValue={initialValues?.typeUser} />
							</Stack>
							<Stack direction={"row"} gap={2}>
								<FormSelectReportGroupBy required onChange={handleChangeGroupBy} name="groupsBy" defaultValue={initialValues?.groupsBy}/>
								<FormSelectReportColumns required name="columns" defaultValue={initialValues?.columns} />
							</Stack>
							{groups.length > 0 && (
								<>
									<Stack direction="row" sx={{ flexWrap: "wrap", width: "100%" }}>
										<Typography variant="h6">Filtros</Typography>
									</Stack>
									<Stack direction="row" gap={2} sx={{ flexWrap: "wrap", width: "100%" }}>
										{_.map(groups, (group) => {
											return <FormSelectColumnValues key={group} label={dictionary[group].label} name={`filters.${group}`} column={group} defaultValue={initialValues?.filters?.[group]} />;
										})}
									</Stack>
								</>
							)}
						</Stack>
					</ModalFrame>
				)}
			/>
		</>
	);
}
