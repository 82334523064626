import { gql } from "@apollo/client";

export const getContacts = gql`
	query ($dateFrom: String!, $dateTo: String!) {
		contacts: getContacts(dateFrom: $dateFrom, dateTo: $dateTo) {
			id
			contactId
			firstName
			lastName
			phoneNumber
			email
			country
			language
			tags
			status
			lifecycle
			assignee
			lastInteractionTime
			createdAt
			channels
			alliance
			product
			internalAllianceId
			productId
			saleDate
			address
			floor
            
			location
			installationDate
			installationTime
			megatoneSellerId
			fravegaSellerId
			rodoSellerId
			dni
			installments
			card
			paymentLink
			survey
			uninstallation
			appId
			service
			branch
			purchaseMedium
			botExperience
			installerAttention
			advisorAttention
			generalExperienceWithManno
			wouldRehire
			claims
			balconyTerrace

			followup24Newsan
			followup48
			followup72
			followup24Megatone
			followup24Fravega

			invoiceNumber
			province
			city
			mannoCity
			vtOnCity
			followup24Musimundo
			saleCompleted
			saleWithInstallation
			seller
			sellerName
			sellerLedger
			abandoned
			deciding
			typeUser
		}
	}
`;



export const getContactReport = gql`
	query ($dateFrom: String!, $dateTo: String!, $groupsBy: [String]!, $segmentsBy: [String], $columns: [String], $filters: JSON, $typeUser: [String]) {
		reports: getContactReport(dateFrom: $dateFrom, dateTo: $dateTo, groupsBy: $groupsBy, segmentsBy: $segmentsBy, columns: $columns, filters: $filters, typeUser: $typeUser)
	}
`;
