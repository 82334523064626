import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import _ from "lodash";
import * as React from "react";
import useColumnsDictionary from "../hooks/useColumnsDictionary";
import { useField } from 'react-final-form';
import { FormHelperText } from "@mui/material";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};

export default function FormSelectReportColumns({ onChange, name, defaultValue, ...props }) {
	const { input, meta } = useField(name);
	const dictionary = useColumnsDictionary();
	const columns = React.useMemo(() => {
		const newColumns = [];
		_.forEach(dictionary, (field, key) => {
			if (field.columns) {
				newColumns.push({ id: key, label: field.label });
			}
		});
		return newColumns;
	}, [dictionary]);
	const [selectedColumns, setSelectedColumns] = React.useState(defaultValue || []);

	const handleChange = (event) => {
		if (input?.onChange) {
			input.onChange(event);
		  }
		const ids = event.target.value;
		setSelectedColumns(ids);
		if (onChange) {
			onChange(ids);
		}
	};

	return (
		<div>
			<FormControl sx={{  width: 290 }}>
				<InputLabel id="demo-multiple-checkbox-label">Columnas</InputLabel>
				<Select
					multiple
					input={<OutlinedInput label="Tag" />}
					renderValue={(selected) => selected.map((id) => columns.find((column) => column.id === id).label).join(", ")}
					MenuProps={MenuProps}
					{...props}
					{...input}
					onChange={handleChange}
					value={selectedColumns}
					error={meta.error && meta.touched}
					helperText={meta?.error}>
					{columns.map((column) => (
						<MenuItem key={column.id} value={column.id}>
							<Checkbox checked={selectedColumns.includes(column.id)} />
							<ListItemText primary={column.label} />
						</MenuItem>
					))}
				</Select>
				{meta.error && meta.touched && <FormHelperText error={meta.error && meta.touched}>{meta?.error}</FormHelperText>}
			</FormControl>
		</div>
	);
}
