import { useMutation, useQuery } from "@apollo/client";
import AddIcon from "@mui/icons-material/Add";
import { Box, Button, Grid, Stack } from "@mui/material";
import * as React from "react";

import { getPageConfig, upsertPageConfig } from "../GQLQueriesPages";
import CustomerReportChart from "./CustomerReportChart";
import ModalEditReport from "./ModalEditReport";

const CUSTOMER_REPORTS_CHARTS_CODE = "customer-reports-charts";

function CustomerReportsTables() {
	const { data } = useQuery(getPageConfig, { variables: { code: CUSTOMER_REPORTS_CHARTS_CODE } });
	const [upsert] = useMutation(upsertPageConfig);
	const [reports, setReports] = React.useState([]);
	const [isOpen, setIsOpen] = React.useState(false);
	const handleOpenModal = () => {
		setIsOpen(true);
	};
	const handleCloseModal = () => {
		setIsOpen(false);
	};
	const handleSubmit = (report) => {
		let newReports = [report, ...reports];
		upsert({ variables: { code: CUSTOMER_REPORTS_CHARTS_CODE, config: newReports } });
		setReports(newReports);
		setIsOpen(false);
	};
	const handleDelete = (index) => {
		let newReports = reports.filter((_, i) => i !== index);
		upsert({ variables: { code: CUSTOMER_REPORTS_CHARTS_CODE, config: newReports } });
		setReports(newReports);
	};
	const handleEdit = (index,values) => {
		let newReports = reports.map((report,i) => i === index ? values : report);
		upsert({ variables: { code: CUSTOMER_REPORTS_CHARTS_CODE, config: newReports } });
		setReports(newReports);
	};
	React.useEffect(() => {
		let newReports = [];
		if (data?.pageconfig?.config) {
			newReports = data.pageconfig.config;
		}

		setReports(newReports);
	}, [data]);

	return (
		<Stack direction="column" spacing={2}>
			<Box sx={{ display: "flex", justifyContent: "flex-end" }}>
				<Button variant="contained" color="primary" onClick={handleOpenModal}>
					<AddIcon /> Agregar grafico
				</Button>
			</Box>
			<ModalEditReport isOpen={isOpen} onClose={handleCloseModal} onSubmit={handleSubmit} isChart={true} />
			<Grid container spacing={2}>
				{reports.map((report,index) => (
					<Grid item xs={12}>
						<CustomerReportChart key={report.title} typeUser={report.typeUser} groupsBy={report.groupsBy} columns={report.columns} title={report.title} filters={report.filters} 
						onDelete={() => handleDelete(index)}
						onEdit={(values) => handleEdit(index,values)}
						/>
					</Grid>
				))}
			</Grid>
		</Stack>
	);
}

export default CustomerReportsTables;
