import { Box, Stack, Tab, Tabs, Typography } from "@mui/material";
import React, { useCallback, useRef, useState } from "react";
import { CustomDateRangePicker } from "../../components";
import { CustomerListResults } from "./components/customer-list-results";
import CustomerReportsCharts from "./components/CustomerReportsCharts";
import CustomerReportsTables from "./components/CustomerReportsTables";
import CustomTabPanel from "./components/CustomTabPanel";
import UploadCSVButton from "./components/UploadCSVButton";
import ImporCsvStatus from "./components/ImporCsvStatus";

export default function ReportingScreen() {
	const [keyReload, setKeyReload] = useState(new Date().getTime().toString());
	
	
    const [tab, setTab] = useState(0)
    const handleChange = (event, newValue) => {
        setTab(newValue);
    };
	const reload = useCallback(() => {
		setKeyReload(new Date().getTime().toString());
	}, []);
	return (
		<>
			<Stack direction="row" justifyContent="space-between">
				<Typography sx={{ m: 1 }} variant="h4">
					Reportes
				</Typography>
				<CustomDateRangePicker />
				<UploadCSVButton reload={reload} />
			</Stack>
			<Stack sx={{ mt: 1, mb: 1 }}>
				<ImporCsvStatus key={keyReload} />
			</Stack>
			<Box sx={{ borderBottom: 1, borderColor: "divider" }} >
				<Tabs value={tab} onChange={handleChange} aria-label="basic tabs example">
					<Tab label="Tabla" />
					<Tab label="Reportes" />
					<Tab label="Gráficos" />
				</Tabs>

				<CustomTabPanel  show={tab === 0}>
					<CustomerListResults key={keyReload}/>
				</CustomTabPanel>
				<CustomTabPanel  show={tab === 1}>
					<CustomerReportsTables key={keyReload}/>
				</CustomTabPanel>
				<CustomTabPanel  show={tab === 2}>
					<CustomerReportsCharts key={keyReload}/>
				</CustomTabPanel>
			</Box>
		</>
	);
}
